import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { CoreModule } from './core/core.module';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { AgmCoreModule } from '@agm/core';
import { JoyrideModule } from 'ngx-joyride';
import { NgSelectModule } from '@ng-select/ng-select';
import { ScrollService } from './auth/services/scroll-to-element.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    ToastNotificationsModule.forRoot({
      preventDuplicates: true,
    }),
    NgxMaskModule.forRoot({
      // clearIfNotMatch : false
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCPaWZ82O2KbXCsZwnZXq0Qe_kKu-SL_WA',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    JoyrideModule.forRoot(),
    BrowserAnimationsModule,
    NgChartsModule,
    NgSelectModule,
  ],
  providers: [DatePipe, ScrollService],
  bootstrap: [AppComponent],
})
export class AppModule {}
