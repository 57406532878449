import { ApiEndpoints } from './../config/api-endpoints.config';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FaqList } from 'src/app/share/models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url: any = environment.apiUrls.baseApiUrl;
  constructor(private http: HttpClient) { }

  createHttpParams(data: any): HttpParams {
    let params = new HttpParams();
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== null) {
        params = params.set(key, data[key]);
      }
    });
    return params;
  }

  // country list and state list  api

  getCountryList() {
    return this.http.get<any>(this.url + ApiEndpoints.Common.CountryList);
  }

  getStateList(queryParams: any) {
    const country = queryParams?.country;
    const url = `${this.url}${ApiEndpoints.Common.StateList}?country=${country}`;
    return this.http.get<any>(url);
  }

  getCityList(queryParams: any) {
    const state = queryParams?.state;
    const url = `${this.url}${ApiEndpoints.Common.CityList}?state=${state}`;
    return this.http.get<any>(url);
  }

  // user apis start
  getUserScanCount() {
    return this.http.get<any>(this.url + ApiEndpoints.Users.GetUserScanCount);
  }

  getUserDetail(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Users.GetUserDetail + '/' + id
    );
  }

  getSubAdminAccountSetting() {
    return this.http.get<any>(
      this.url + ApiEndpoints.SubAdmin.GetSubAdminAccountSetting
    );
  }

  getUserPositionTag() {
    return this.http.get<any>(this.url + ApiEndpoints.Users.UserTag);
  }
  getUsersListSubAdmin(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const status = data?.status;
    const sort = data?.sort;
    const userPositionTag = data?.userPositionTag;
    const accountType = data?.accountType;
    const role = data?.role;
    const type = data?.type || '';
    const url = `${this.url}${ApiEndpoints.Users.GetUserDetail}?search=${search}&accountSortOrder=updatedAt,DSC&accountType=${accountType}&userPositionTag=${userPositionTag}&roleCode=${role}&sortOrder=${sort}&status=${status}&pageNo=${pageNo}&pageSize=${pageSize}&type=${type}`;
    return this.http.get<any>(url);
  }

  getUsersList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const status = data?.status;
    const sort = data?.sort;
    // const userPositionTag = data?.userPositionTag;
    const accountType = data?.accountType;
    const role = data?.role;
    const parentUserId = data?.parentUserId || '';
    const url = `${this.url}${ApiEndpoints.Users.GetUserDetail}?search=${search}&accountSortOrder=updatedAt,DSC&accountType=${accountType}&roleCode=${role}&sortOrder=${sort}&status=${status}&pageNo=${pageNo}&pageSize=${pageSize}&parentUserId=${parentUserId}`;
    return this.http.get<any>(url);
  }

  loginToSubAdminAcc(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Users.LoginToSubAdminAcc,
      data
    );
  }

  logoChange(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.SubAdmin.logoChangeSubAdmin + '/' + id,
      data
    );
  }

  deleteCustomerProfile(id: any, data: any, type: any) {
    let item = {
      accountType: type,
      userId: id,
    };
    return this.http.put<any>(
      this.url + ApiEndpoints.DeleteUserProfile.DeleteUserProfile + '/' + data,
      item
    );
  }

  getInvoiceUsersList(data?: any) {
    const tag = data || '';
    const url = `${this.url}${ApiEndpoints.Users.GetShipMentUserDetail}?filter=${tag}`;
    return this.http.get<any>(url);
  }

  getProInvoicesListOld(userId: string) {
    const url = `${this.url}${ApiEndpoints.Invoices.ShipmentInvoices}?userId=${userId}&invoiceVersion=normal&paymentStatus=unpaid&isAddedToSpecialInvoice=false&serverPaging=false`;
    return this.http.get<any>(url);
  }

  getProInvoicesList(userId: string, currentInvoiceId: string) {
    const url = `${this.url}${ApiEndpoints.Invoices.ShipmentInvoices}?userId=${userId}&invoiceVersion=normal&paymentStatus=unpaid&isAddedToSpecialInvoice=false&currentInvoiceId=${currentInvoiceId}&serverPaging=false`;
    return this.http.get<any>(url);
  }

  downloadForm(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.DownloadForm.DownloadMailboxForm,
      data
    );
  }

  createSpecialInvoice(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.CreateSpecialInvoice,
      data
    );
  }

  calculateAmount(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Invoices.Calculate + '/' + id,
      data
    );
  }
  updateUserStatus(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Users.GetUserDetail + '/' + id,
      data
    );
  }

  deleteUserForAdmin(id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Users.DeleteUserForAdmin + '/' + id,
      ''
    );
  }

  changeUserPosition(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Users.ChangeUserPosition + '/' + id,
      data
    );
  }

  inviteCustomer(data: any) {
    return this.http.post<any>(this.url + ApiEndpoints.Users.InviteUser, data);
  }

  changeUserEmail(id: string, data: any) {
    return this.http.post<any>(this.url + ApiEndpoints.Users.ChangeEmail + '/' + id, data);
  }

  addUser(data: any) {
    return this.http.post<any>(this.url + ApiEndpoints.Users.AddUser, data);
  }

  addSubAdmin(data: any, id: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.SubAdmin.AddSubAdmin + '/' + id,
      data
    );
  }

  deleteSubAdmin(id: any, data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.SubAdmin.DeleteSubAdmin + '/' + id,
      data
    );
  }

  updateAdminToSuperSubadmin(payload: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.SubAdmin.MakeSuperSubadmin,
      payload
    );
  }

  upgradeAccount(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Users.UpgradeAccount + '/' + id,
      data
    );
  }

  updateUserTour(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Users.updateTour + '/' + id,
      data
    );
  }

  // user apis end

  createSenderAddress(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Shipments.CreateSenderAddress,
      data
    );
  }
  updateSenderAddress(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.UpdateSenderAddress + '/' + id,
      data
    );
  }
  createReceiverAddress(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.CreateReceiverAddress + '/' + id,
      data
    );
  }

  getShipmentDetail(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Shipments.GetShipments + '/' + id
    );
  }

  verifyAddress(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Shipments.VerifyAdress,
      data
    );
  }

  addPackage(data: any, id: string, type?: string) {
    if (type == 'fedEx') {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.AddFedExPackage + '/' + id,
        data
      );
    } else {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.AddPackageForUps + '/' + id,
        data
      );
    }
  }

  addOneRateFedExPackage(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.AddFedExOneRatePackage + '/' + id,
      data
    );
  }

  getCombineServices(requestBody: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.GetAllServices + '/' + id,
      requestBody
    );
  }
  getUPSServices(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.AddPackageForUps + '/' + id,
      data
    );
  }
  selectService(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.SelectService + '/' + id,
      data
    );
  }

  updateQuotes(data: any, id: string, carrierType?: any) {
    if (carrierType == 'fedEx') {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.UpdateQuotes + '/' + id,
        data
      );
    } else if (carrierType == 'ups') {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.UpdateQuotesForUps + '/' + id,
        data
      );
    } else {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.UpdateQuotes + '/' + id,
        data
      );
    }
  }
  updateProfile(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Users.UpdateProfile + '/' + id,
      data
    );
  }
  UpdatePassword(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Auth.UpdatePassword + '/' + id,
      data
    );
  }
  // shipments apis

  copyShipment(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Shipments.CopyShipment,
      data
    );
  }

  createShipment(id: string, data: any, carrierType: any) {
    if (carrierType == 'fedEx') {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.CreateShipment + '/' + id,
        data
      );
    } else if (carrierType == 'ups') {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.CreateUpsShipment + '/' + id,
        data
      );
    } else {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.CreateShipment + '/' + id,
        data
      );
    }
  }

  createReturnShipment(id: string, data: any, carrierType: string) {
    if (carrierType == 'FEDEX') {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.CreateReturnShipment + '/' + id,
        data
      );
    } else if (carrierType == 'UPS') {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.CreateReturnUpsShipment + '/' + id,
        data
      );
    } else {
      return this.http.put<any>(
        this.url + ApiEndpoints.Shipments.CreateShipment + '/' + id,
        data
      );
    }
  }

  cancelShipment(id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.CancelShipment + '/' + id,
      ''
    );
  }
  DeleteCancelShipment(id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.DeleteCancelShipment + '/' + id,
      ''
    );
  }
  paymentShipment(id: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Shipments.PaymentShipment,
      id
    );
  }

  uploadBulkShipment(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Shipments.BulkShipment,
      data
    );
  }

  getShipments(data: any) {
    const userId = '';
    // data?.userId
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const status = data?.shipmentStatus;
    const date = data?.shipmentDate;
    const sort = data?.sort || 'createdAt,DSC';
    const shipmentListType = data?.shipmentListType
      ? data?.shipmentListType
      : '';
    const url = `${this.url}${ApiEndpoints.Shipments.GetShipments}?createdBy=${userId}&shipmentListType=${shipmentListType}&sortOrder=${sort}&date=${date}&search=${search}&status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }
  getCustomerShipments(data: any) {
    const userId = data?.userId;
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const status = data?.shipmentStatus;
    const date = data?.shipmentDate;
    const sort = data?.sort || 'createdAt,DSC';
    const shipmentListType = data?.shipmentListType
      ? data?.shipmentListType
      : '';
    const url = `${this.url}${ApiEndpoints.Shipments.GetShipments}?createdBy=${userId}&shipmentListType=${shipmentListType}&sortOrder=${sort}&date=${date}&search=${search}&status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }
  getBulkShipments(data: any) {
    const userId = data?.userId;
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const sort = data?.sort;
    // const shipmentListType = data?.shipmentListType &shipmentListType=${shipmentListType}
    const url = `${this.url}${ApiEndpoints.Shipments.GetBulkShipments}?createdBy=${userId}&sortOrder=${sort}&search=${search}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  deleteBulkShipments(id: string) {
    return this.http.delete<any>(
      this.url + ApiEndpoints.Shipments.DeleteBulkShipments + '/' + id
    );
  }
  deleteShipment(id: string) {
    return this.http.delete<any>(
      this.url + ApiEndpoints.Shipments.DeleteShipment + '/' + id
    );
  }

  printLabel(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Shipments.PrintLabel + '/' + id
    );
  }

  printLabel2(id: string, label: string) {
    return this.http.get<any>(
      this.url +
      ApiEndpoints.Shipments.PrintLabel +
      '/' +
      id +
      '?labelType=' +
      label
    );
  }

  printMultipleLabel(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Shipments.PrintMultipleLabel + '/' + id
    );
  }

  // Address api start

  getAddresDetail(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Addresses.GetAddressDetail + '/' + id
    );
  }

  getAddresess(data: any) {
    const userId = data.userId;
    const pageNo = data.pageNo;
    const pageSize = data.pageSize;
    const search = data.search;
    const addressType = data.addressType;
    // const status = data.addressTags;
    const addressInputType = data?.addressInputType;
    const sort = data?.sort;
    const url = `${this.url}${ApiEndpoints.Addresses.GetAddress}?user=${userId}&sortOrder=${sort}&addressType=${addressType}&search=${search}&addressInputType=${addressInputType}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  exportAddressList(id: any) {
    const userId = id;
    const url = `${this.url}${ApiEndpoints.Addresses.ExportAddress}?userId=${userId}`;
    return this.http.get<any>(url);
  }

  importAddressList(file: any) {
    let data = new FormData();
    data.append('file', file);
    // const userId = id
    // const url = `${this.url}${ApiEndpoints.Addresses.ImportAddress}`;
    // return this.http.get<any>(url)
    return this.http.post<any>(
      this.url + ApiEndpoints.Addresses.ImportAddress,
      data
    );
  }

  getDefaultAddresses(data: any) {
    let url = `${this.url}${ApiEndpoints.Addresses.GetAddress}?addressType=${data?.addressType}&user=${data?.user}`;

    if (data?.serverPaging !== undefined) {
      url += `&serverPaging=${data?.serverPaging}`;
    }

    return this.http.get<any>(url);
  }

  addAddress(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Addresses.AddAddress,
      data
    );
  }

  updateAddress(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Addresses.UpdateAddress + '/' + id,
      data
    );
  }

  swapAddress(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Shipments.SwapAddress,
      data
    );
  }

  addTags(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Addresses.AddAddress + '/' + id,
      data
    );
  }

  deleteAddress(id: any) {
    return this.http.delete<any>(
      this.url + ApiEndpoints.Addresses.DeleteAddress + '/' + id
    );
  }

  // packages  api  start

  getPackageList(data: any) {
    const userId = data?.userId;
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const packageType = data?.packageType;
    const sort = data?.sort;
    const url = `${this.url}${ApiEndpoints.Packages.GetPackagesDetail}?createdBy=${userId}&sortOrder=${sort}&search=${search}&packageType=${packageType}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }
  getPackagesById(userId: any) {
    const url = `${this.url}${ApiEndpoints.Packages.GetPackagesDetail}?createdBy=${userId}`;
    return this.http.get<any>(url);
  }

  getPackageDetail(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Packages.GetPackageDetail + '/' + id
    );
  }

  updatePackageDetail(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Packages.GetPackageDetail + '/' + id,
      data
    );
  }
  addPackageDetail(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Packages.AddPackage,
      data
    );
  }

  deletePackage(id: any) {
    return this.http.delete<any>(
      this.url + ApiEndpoints.Packages.DeletePackage + '/' + id
    );
  }

  // packages  api  end

  // faq  api  start

  getFaqList(data: any) {
    const search = data?.search;
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const url = `${this.url}${ApiEndpoints.Faq.GetFaq}?search=${search}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  addFaq(data: FaqList) {
    return this.http.post<any>(this.url + ApiEndpoints.Faq.AddFaq, data);
  }

  updateFaq(data: FaqList, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Faq.EditFaq + '/' + id,
      data
    );
  }

  deleteFaq(id: string) {
    return this.http.delete<any>(
      this.url + ApiEndpoints.Faq.DeleteFaq + '/' + id
    );
  }

  // faq  api  end

  // analytics and report  api  start

  getLogs() {
    return this.http.get<any>(this.url + ApiEndpoints.AnalyticsAndReports.Logs);
  }
  getLocations() {
    return this.http.get<any>(
      this.url + ApiEndpoints.AnalyticsAndReports.Locations
    );
  }

  getRevenue(data: any) {
    const search = data?.search;
    const startDate = data?.startDate;
    const endDate = data?.endDate;
    const url = `${this.url}${ApiEndpoints.AnalyticsAndReports.Revenue}?search=${search}&startDate=${startDate}&endDate=${endDate}`;
    return this.http.get<any>(url);
  }

  // analytics and report  api end

  // dashboard  api  start
  getDashboardStatus() {
    return this.http.get<any>(this.url + ApiEndpoints.Dashboard.Status);
  }

  getShipmentSavingGraph(data: any) {
    const year = data?.year;
    const url = `${this.url}${ApiEndpoints.Dashboard.ShipmentDashboardSaving}?year=${year}`;
    return this.http.get<any>(url);
  }
  getShipmentsCountGraph(data: any) {
    const fromDate = data?.fromDate;
    const toDate = data?.toDate;
    const url = `${this.url}${ApiEndpoints.Dashboard.ShipmentDashboarShipmentCount}?startDate=${fromDate}&endDate=${toDate}`;
    return this.http.get<any>(url);
  }

  getMailboxGraphData(data: any) {
    const year = data?.year;
    const url = `${this.url}${ApiEndpoints.Dashboard.MailboxGraph}?year=${year}`;
    return this.http.get<any>(url);
  }

  getMailboxCountData(data: any) {
    const fromDate = data?.fromDate;
    const toDate = data?.toDate;
    const url = `${this.url}${ApiEndpoints.Dashboard.MailboxCount}?startDate=${fromDate}&endDate=${toDate}`;
    return this.http.get<any>(url);
  }

  getAdminAccountCount(data: any) {
    const year = data?.year;
    const url = `${this.url}${ApiEndpoints.Dashboard.AdminAccountGraph}?year=${year}`;
    return this.http.get<any>(url);
  }

  subAdminDashboardData() {
    const url = `${this.url}${ApiEndpoints.Dashboard.SubAdminAccountGraph}`;
    return this.http.get<any>(url);
  }
  subAdminCommissionTransationList() {
    const url = `${this.url}${ApiEndpoints.Dashboard.SubAdminCommissionList}`;
    return this.http.get<any>(url);
  }

  subAdminCommissionUserList() {
    const url = `${this.url}${ApiEndpoints.Dashboard.SubAdminCommissionUserList}`;
    return this.http.get<any>(url);
  }

  // dashboard  api  end

  // carrier apis start

  getCarrierDetail() {
    return this.http.get<any>(this.url + ApiEndpoints.Carrier.Carriers);
  }

  // carrier apis end

  // margin and discount apis start

  getMarginAndDiscountDetail() {
    return this.http.get<any>(
      this.url + ApiEndpoints.MarginAndDiscount.MarginAndDiscount
    );
  }
  updateMargin(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MarginAndDiscount.MarginAndDiscount + '/' + id,
      data
    );
  }
  updateDiscount(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MarginAndDiscount.MarginAndDiscount + '/' + id,
      data
    );
  }
  updateVipDiscount(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MarginAndDiscount.VipMarginAndDiscount + '/' + id,
      data
    );
  }

  // margin and discount apis end

  // mailBox  api start
  getMailboxProfile(id: any) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Users.UpdateProfile + '/' + id
    );
  }
  cancelMailboxScan(id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.CancelLabels + '/' + id,
      ''
    );
  }

  MarkAsPickup(id: any) {
    const today = new Date();
    const formattedDate = today.toISOString();

    let data = {
      status: 'archived',
      action: 'inActive',
      labelRequestedStatus: 'inActive',
      lastActionAdmin: 'labelDelivered',
      lastActionUser: 'labelDelivered',
      sendMail: true,
      finalPickedUpDate: formattedDate,
    };
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.MarkAsPickup + '/' + id,
      data
    );
  }

  MarkAsPickupMultiple(Ids: any) {
    let data = { idsToUpdate: Ids };

    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.MarkAsPickupMultiple,
      data
    );
  }
  sendToArchive(id: any) {
    let data = {
      status: 'archived',
      action: 'inActive',
    };
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.SendToArchive + '/' + id,
      data
    );
  }

  sendToResubmit(id: any) {
    let data = {
      forwardedAddress: null,
      forwardMailCharges: null,
      lastActionUser: null,
      lastActionAdmin: 'labelAssignedToUser',
      status: 'active',
      action: 'inActive',
    };
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.SendToArchive + '/' + id,
      data
    );
  }

  getMailInboxList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const status = data?.status;
    const type = data?.type;
    const date = data?.date;
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.InboxList}?status=${status}&lastActionAdmin=${type}&search=${search}&date=${date}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }
  getMailArchiveList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const date = data?.date;
    const status = data?.status;
    const type = data?.type;
    // const profileId = data?.profileId;
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.InboxList}?status=${status}&action=${type}&search=${search}&date=${date}&pageNo=${pageNo}&pageSize=${pageSize}`;
    // &profileId=${profileId}
    return this.http.get<any>(url);
  }
  getMailTrashList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const status = data?.status;
    const search = data?.search;
    const date = data?.date;
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.InboxList}?status=${status}&search=${search}&date=${date}`;
    // &pageNo=${pageNo}&pageSize=${pageSize}
    return this.http.get<any>(url);
  }

  getApprovedMailboxList() {
    // const pageNo = ''
    // const pageSize = ''
    // &pageNo=${pageNo}&pageSize=${pageSize}
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.Profiles}?accountType=virtualMailBox&status=approved&pageSize=5000`;
    return this.http.get<any>(url);
  }

  getMailboxScansCount(id: any) {
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.GetMailboxScansCount + '/' + id
      }`;
    return this.http.get<any>(url);
  }
  assignLabel(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.AssignLabels,
      data
    );
  }
  // Fixme change end point when api call
  assignMailboxPlanToUser(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.AssignPlanToUser,
      data
    );
  }
  assignMailboxScanCount(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.AssignScanCount,
      data
    );
  }
  deleteMailbox(id: any, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.PermanentDeleteMailbox + '/' + id,
      data
    );
  }

  getUserMailboxDetail(data: any, id: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    // const url = `${this.url}${ApiEndpoints.MailboxProfiels.AssignLabels}?profileId=${id}&pageSize=1000`;
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.AssignLabels}?profileId=${id}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }
  // &labelRequestedStatus=active serverPaging=false
  assignOpenAndScanRes(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.OpenAndScanRes + '/' + id,
      data
    );
  }
  forwardMail(id: any, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.ForwardLabel + '/' + id,
      data
    );
  }
  forwardMultipleMail(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.ForwardMultipleLabel,
      data
    );
  }
  verifyOtp(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.HoldForPickupVerify + '/' + id,
      data
    );
  }
  holdForPickUp(id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.HoldForPickup + '/' + id,
      ''
    );
  }
  holdForPickUpOtp(id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.HoldForPickupOtp + '/' + id,
      ''
    );
  }
  holdForPickUpMultipleOtp(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.HoldForPickupMultipleOtp,
      data
    );
  }

  openAndScanReq(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.OpenAndScanReq,
      data
    );
  }

  forwordMailBox(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.ForwardLabel + '/' + id,
      data
    );
  }

  notMyMail(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.NotMyMail,
      data
    );
  }
  sendInTrash(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.AddInTrash,
      data
    );
  }

  sendInShred(id: any, data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.AddInShred + id,
      data
    );
  }

  getLabelDetail(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.MailboxProfiels.AssignLabels + '/' + id
    );
  }

  addShipmentDateForForwardLabel(id: any, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.AddShipmentDate + '/' + id,
      data
    );
  }

  getMailBoxRequestList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const sort = data?.sort;
    const acountType = data?.acountType;
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.Profiles}?search=${search}&sortOrder=${sort}&accountSortOrder=updatedAt,DSC&accountType=${acountType}&status=pending&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }
  // getMailBoxOrderList(data: any) {
  //   const pageNo = data?.pageNo;
  //   const pageSize = data?.pageSize;
  //   const search = data?.search;
  //   const toDate = data?.toDate;
  //   const fromDate = data?.fromDate;
  //   const assigne = data?.assigne;
  //   const sort = data?.sort;
  //   const status = data?.status;
  //   const url = `${this.url}${ApiEndpoints.MailboxProfiels.Profiles}?search=${search}&accountSortOrder=${sort}&accountType=virtualMailBox&status=${status}&startDate=${fromDate}&endDate=${toDate}&isAssigne=${assigne}&pageNo=${pageNo}&pageSize=${pageSize}`;
  //   return this.http.get<any>(url);
  // }

  getMailBoxOrderList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const toDate = data?.toDate;
    const fromDate = data?.fromDate;
    const assigne = data?.assigne;
    const sort = data?.sort;
    const serverPaging = data?.serverPaging || '';
    const isMessageAlert = data?.isMessageAlert;
    const mailBoxStatus = data?.mailBoxStatus;
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.MailBoxes}?search=${search}&serverPaging=${serverPaging}&isMessageAlert=${isMessageAlert}&accountSortOrder=${sort}&accountType=virtualMailBox&status=${mailBoxStatus}&startDate=${fromDate}&endDate=${toDate}&isAssigne=${assigne}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  mailBoxPost(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.MailBoxesPost,
      data
    );
  }

  mailboxNumberList(id: any) {
    return this.http.get<any>(
      this.url + ApiEndpoints.MailboxProfiels.mailBoxNumberList + '/' + id
    );
  }

  getMailBoxesUniqueNumber(id: any) {
    return this.http.get<any>(
      this.url + ApiEndpoints.MailboxProfiels.MailBoxUniqueNumber + '/' + id
    );
  }

  markActiveInactive(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.MarkActiveInactive + '/' + id,
      data
    );
  }

  getMailBoxRequestDetail(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.MailboxProfiels.Profiles + '/' + id
    );
  }

  getMailBoxPlans(data?: string) {
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.Plans}?validity=${data}`;
    return this.http.get<any>(url);
  }

  changeDueDate(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.ChangeDueDate + '/' + id,
      data
    );
  }
  removeUserfromMailbox(id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.RemoveUserFromMailBox + '/' + id,
      {}
    );
  }
  addRemovePopular(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.Plans + '/' + id,
      data
    );
  }
  getMailBoxPlanById(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.MailboxProfiels.Plans + '/' + id
    );
  }
  addMailboxPlans(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.Plans,
      data
    );
  }
  updateMailboxPlans(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.Plans + '/' + id,
      data
    );
  }

  deletePlan(id: string, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.Plans + '/' + id,
      data
    );
  }

  buyMailBoxPlans(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.BuyPlans,
      data
    );
  }

  cancleSubscriptionPlan(id: any, data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.CancleSubscriptionPlan + '/' + id,
      data
    );
  }

  approveRejectShipmentRequest(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.ApproveOrReject + '/' + id,
      data
    );
  }
  approveRejectMailBoxRequest(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.ApproveReject + '/' + id,
      data
    );
  }

  getMaialboxStoreList() {
    const url = `${this.url}${ApiEndpoints.MailboxProfiels.mailBoxStore}`;
    return this.http.get<any>(url);
  }

  reassignMailbox(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.Profiles + '/' + id,
      data
    );
  }
  paymentVerification(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.Payment,
      data
    );
  }
  shipmentPaymentVerification(data: any) {
    return this.http.post<any>(this.url + ApiEndpoints.Shipments.Payment, data);
  }

  updateForwardQuots(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.ForwardResponse,
      data
    );
  }

  selectForwardPlan(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.MailboxProfiels.SelectForwardPlan + '/' + id,
      data
    );
  }

  selectForwardPlanPayment(id: any) {
    return this.http.put<any>(
      this.url +
      ApiEndpoints.MailboxProfiels.SelectForwardPlanPayment +
      '/' +
      id,
      ''
    );
  }

  ForwardLabelPayment(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.MailboxProfiels.CheckForwardPayment,
      data
    );
  }

  // mailBox  api end

  // invoice api start

  getShipmentInvoiceList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const date = data?.date;
    const status = data?.payStatus;
    const sort = data?.sort;
    const isAddedToSpecialInvoice = data?.isAddedToSpecialInvoice;
    const url = `${this.url}${ApiEndpoints.Invoices.ShipmentInvoices}?search=${search}&sortOrder=${sort}&paymentStatus=${status}&pageNo=${pageNo}&date=${date}&pageSize=${pageSize}&isAddedToSpecialInvoice=${isAddedToSpecialInvoice}`;
    return this.http.get<any>(url);
  }

  getSubAdminMandatoryInvoiceList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const date = data?.date;
    const status = data?.payStatus;
    const sort = data?.sort;
    const userId = data?.userId;
    const url = `${this.url}${ApiEndpoints.Invoices.SubAdminMandatoryInvoiceList}?search=${search}&invoiceType=assignByAdmin&userId=${userId}&sortOrder=${sort}&paymentStatus=${status}&pageNo=${pageNo}&date=${date}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }
  getSubInvoiceList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const date = data?.date;
    const status = data?.payStatus;
    const sort = data?.sort;
    const url = `${this.url}${ApiEndpoints.Invoices.SubAdminInvoices
      }?search=${search}&invoicesOf=${'SUB_ADMIN'}&sortOrder=${sort}&paymentStatus=${status}&pageNo=${pageNo}&date=${date}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  getMailBoxInvoiceList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const date = data?.date;
    const status = data?.payStatus;
    const sort = data?.sort;
    const url = `${this.url}${ApiEndpoints.Invoices.MailboxInvoices}?search=${search}&sortOrder=${sort}&paymentStatus=${status}&pageNo=${pageNo}&date=${date}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  getSubAdminInvoiceList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const date = data?.date;
    const status = data?.payStatus;
    const sort = data?.sort;
    const invoicesOf = data?.invoicesOf;
    const url = `${this.url}${ApiEndpoints.Invoices.SubAdminInvoices}?search=${search}&invoicesOf=${invoicesOf}&sortOrder=${sort}&paymentStatus=${status}&pageNo=${pageNo}&date=${date}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  cancelInvoice(id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Invoices.CancelInvoice + '/' + id,
      ''
    );
  }

  cancelSubAdminInvoice(id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Invoices.CancelSubAdminInvoice + '/' + id,
      ''
    );
  }

  cancelSpecialInvoice(id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Invoices.CancelSpecialInvoice + '/' + id,
      ''
    );
  }

  // cancelMailboxInvoice(id: any) {
  //   return this.http.put<any>(
  //     this.url + ApiEndpoints.Invoices.CancelMailboxInvoice + '/' + id,
  //     ''
  //   );
  // }

  cancelMailboxInvoice(payload: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.CancelMailboxInvoice,
      payload
    );
  }

  getMonthlyBillDetails(id: string) {
    const url = `${this.url}${ApiEndpoints.Invoices.MonthlyInvoices}/${id}`;
    return this.http.get<any>(url);
  }

  getShipmentInvoiceDetails(id: string) {
    const url = `${this.url}${ApiEndpoints.Invoices.ShipmentInvoices}/${id}`;
    return this.http.get<any>(url);
  }

  getMonthlyInvoiceList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const paymentStatus = data?.paymentStatus;

    const url = `${this.url}${ApiEndpoints.Invoices.MonthlyInvoices}?search=${search}&paymentStatus=${paymentStatus}&pageNo=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  getUnpaidInvoices() {
    const url = `${this.url}${ApiEndpoints.Invoices.GetUnpaidInvoices}`;
    return this.http.get<any>(url);
  }

  raiseMonthlyBill(id: string) {
    const url = `${this.url}${ApiEndpoints.Invoices.RaiseMonthlyBilling}/${id}`;
    return this.http.post<any>(url, {});
  }

  payMonthlyBill(id: string, type: string = 'unauthenticated') {
    const url = `${this.url}${ApiEndpoints.Invoices.PayMonthlyBilling}/${id}?type=${type}`;
    return this.http.get<any>(url);
  }

  payPendingBill() {
    const url = `${this.url}${ApiEndpoints.Invoices.PayPendingInvoice}`;
    return this.http.post<any>(url, {});
  }

  downloadMonthlyInvoice(id: string) {
    const url = `${this.url}${ApiEndpoints.Invoices.DownloadMonthlyInvoice}/${id}`;
    return this.http.get<any>(url);
  }

  verifyMonthlyBillPayment(payload: any) {
    const url = `${this.url}${ApiEndpoints.Invoices.VerifyMonthlyBillPayment}`;
    return this.http.post<any>(url, payload);
  }

  getPenaltiesList(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const userMonthlyBilling = data.userMonthlyBilling;

    const url = `${this.url}${ApiEndpoints.Invoices.Penalties}?search=${search}&pageNo=${pageNo}&pageSize=${pageSize}&userMonthlyBilling=${userMonthlyBilling}`;
    return this.http.get<any>(url);
  }

  getShipmentInvoiceItems(data: any) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const shipmentInvoice = data.shipmentInvoice;

    const url = `${this.url}${ApiEndpoints.Invoices.ShipmentInvoiceItems}?search=${search}&pageNo=${pageNo}&pageSize=${pageSize}&shipmentInvoice=${shipmentInvoice}`;
    return this.http.get<any>(url);
  }

  createPenalty(payload: any = {}) {
    const url = `${this.url}${ApiEndpoints.Invoices.CreateManualPenalties}`;
    return this.http.post<any>(url, payload);
  }

  createShipmentInvoiceItem(payload: any = {}) {
    const url = `${this.url}${ApiEndpoints.Invoices.ShipmentInvoiceItems}`;
    return this.http.post<any>(url, payload);
  }

  updatePenalty(id: string, payload: any = {}) {
    const url = `${this.url}${ApiEndpoints.Invoices.Penalties}/${id}`;
    return this.http.put<any>(url, payload);
  }

  updateShipmentInvoiceItem(id: string, payload: any = {}) {
    const url = `${this.url}${ApiEndpoints.Invoices.ShipmentInvoiceItems}/${id}`;
    return this.http.put<any>(url, payload);
  }

  attachExitingInvoice(id: string, payload: any = {}) {
    const url = `${this.url}${ApiEndpoints.Invoices.AttachExistingShipmentInvoice}?shipmentInvoice=${id}`;
    return this.http.post<any>(url, payload);
  }

  cancelPenalty(id: string, payload: any = {}) {
    const url = `${this.url}${ApiEndpoints.Invoices.CancelPenalty}/${id}`;
    return this.http.put<any>(url, payload);
  }

  cancelShipmentInvoiceItem(id: string) {
    const url = `${this.url}${ApiEndpoints.Invoices.ShipmentInvoiceItems}/${id}`;
    return this.http.delete<any>(url);
  }

  cancelMultipleUserPenalties(payload: any = {}) {
    const url = `${this.url}${ApiEndpoints.Invoices.CancelMultipleUserPenalties}`;
    return this.http.post<any>(url, payload);
  }

  cancelMultipleShipmentInvoiceItems(payload: any = {}) {
    const url = `${this.url}${ApiEndpoints.Invoices.CancelMultipleUserPenalties}`;
    return this.http.post<any>(url, payload);
  }

  getUnpaidCount() {
    return this.http.get<any>(
      this.url + ApiEndpoints.Invoices.ShipmentInvoicesCount + '?isAddedToSpecialInvoice=false'
    );
  }

  getMailboxUnpaidCount() {
    return this.http.get<any>(
      this.url + ApiEndpoints.Invoices.MailboxInvoicesCount
    );
  }

  getSubMailboxUnpaidCount() {
    return this.http.get<any>(
      this.url + ApiEndpoints.Invoices.getSubMailboxUnpaidCount
    );
  }

  getSubAdminCount() {
    return this.http.get<any>(
      this.url + ApiEndpoints.Invoices.getSubAdminCount
    );
  }

  downloadShipmentInvoice(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Invoices.downloadShipmentInvoice + '/' + id
    );
  }

  downloadMailboxInvoice(id: string) {
    return this.http.get<any>(
      this.url + ApiEndpoints.Invoices.downloadMailboxInvoice + '/' + id
    );
  }

  payForward(id: any) {
    let data = { invoiceId: id };
    return this.http.put<any>(
      this.url + ApiEndpoints.Invoices.PayInvoice + '/' + id,
      ''
    );
  }

  subAdminForward(id: any) {
    let data = { invoiceId: id };
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.paySubAdminInvoice,
      data
    );
  }

  payAdminMailboxForward(id: any) {
    let data = { invoiceId: id };
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.PayAdminMailboxInvoice,
      data
    );
  }

  payAutomaticInvoice(id: any) {
    let data = { invoiceId: id };
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.PayAutomaticPlanInvoice,
      data
    );
  }

  payShipmentInvoice(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.payShipmentInvoice,
      data
    );
  }

  paySubAdminInvoice(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.paySubAdminInvoice,
      data
    );
  }

  shipmentInvoicePayment(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.ShipmentPaymentVerification,
      data
    );
  }

  forwardPayment(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.PaymentVerificationPlan,
      data
    );
  }

  // createMailboxInvoice(data: any) {
  //   return this.http.post<any>(
  //     this.url + ApiEndpoints.Invoices.CreateMailboxInvoice,
  //     data
  //   );
  // }

  createMailboxInvoice(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.CreateMailboxInvoice,
      data
    );
  }

  createShipmentInvoice(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Invoices.CreateShipmentInvoice,
      data
    );
  }

  // invoice api end

  //  card Apis

  addCard(data: any) {
    return this.http.post<any>(this.url + ApiEndpoints.Shipments.AddCard, data);
  }

  deleteCard(id: any) {
    return this.http.delete<any>(
      this.url + ApiEndpoints.Shipments.AddCard + '/' + id
    );
  }

  getCardList(id: any) {
    const UserId = id;
    const url = `${this.url}${ApiEndpoints.Shipments.GetCard}?user=${UserId}`;
    return this.http.get<any>(url);
  }

  getInvoicePaymentStatus() {
    return this.http.get<any>(
      this.url + ApiEndpoints.Shipments.GetShipmentsPaidStatus
    );
  }

  // add address api
  senderAddress(data: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Shipments.AddShipment.AddAddress,
      data
    );
  }

  addProfileDocs(data: any, id: string) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Users.UploadDocs + '/' + id,
      data
    );
  }

  // Notification API's

  getNotifications() {
    return this.http.get<any>(
      this.url + ApiEndpoints.Notifications.GetNotifications
    );
  }

  setConfigPassword(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.setConfigPassword.setPassword,
      data
    );
  }

  updateConfigPassword(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.setConfigPassword.updateConfigPass,
      data
    );
  }

  submitConfigPassword(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Users.verifyConfigPass,
      data
    );
  }

  // Store API's

  getStoreList(data: any) {
    let url = `${this.url}${ApiEndpoints.Store.GetStoreList}`;

    if (data?.serverPaging === false) {
      url += '?serverPaging=false';
    } else {
      const pageNo = data?.pageNo;
      const pageSize = data?.pageSize;
      const search = data?.search;
      const status = data?.status;
      url += `?search=${search}&storeStatus=${status}&pageNo=${pageNo}&pageSize=${pageSize}`;
    }
    return this.http.get<any>(url);
  }

  getStoreMailboxesById(data: any, id: string) {
    const pageNo = data?.pageNo;
    const pageSize = data?.pageSize;
    const search = data?.search;
    const date = data?.date;
    const status = data?.status;
    const sort = data?.sort;
    const fromDate = data?.fromDate;
    const toDate = data?.toDate;
    const isMessageAlert = data?.isMessageAlert;
    return this.http.get<any>(
      `${this.url}${ApiEndpoints.Store.GetStoreMailBoxes}?storeId=${id}&isMessageAlert=${isMessageAlert}&search=${search}&status=${status}&startDate=${fromDate}&endDate=${toDate}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
  }

  getEmptyStore(id: string) {
    return this.http.get<any>(
      `${this.url}${ApiEndpoints.Store.getEmptyStoreList}/${id}`
    );
  }

  addActiveInactiveStore(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Store.switchMailBoxStore,
      data
    );
  }

  deleteInactiveStore(id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Store.deleteInactiveStore + '/' + id,
      ''
    );
  }

  getStoreDataById(id: string) {
    return this.http.get<any>(
      `${this.url}${ApiEndpoints.Store.GetStoreDetail}/${id}`
    );
  }

  addStore(data: any) {
    return this.http.post<any>(this.url + ApiEndpoints.Store.AddStore, data);
  }

  addMoreMailbox(data: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Store.addMoreMailboxes,
      data
    );
  }

  CheckStoreName(data: any) {
    const storeName = data?.name;
    return this.http.get<any>(
      `${this.url}${ApiEndpoints.Store.CheckStoreName}?storeName=${storeName}`
    );
  }

  deleteStore(id: any) {
    return this.http.delete<any>(
      this.url + ApiEndpoints.Store.DeleteStore + '/' + id
    );
  }
  updateStoreStatus(data: any, id: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.Store.UpdateStoreStatus + '/' + id,
      data
    );
  }

  // stripeConnect

  stripeConnect() {
    return this.http.post<any>(
      this.url + ApiEndpoints.stripeConnent.connenctWithStripe,
      ''
    );
  }

  checkAccountStatus() {
    return this.http.post<any>(
      this.url + ApiEndpoints.checkAccountStatus.accountStatus,
      ''
    );
  }

  // User margin and discount

  getMarginAndDiscountCustomers(params: any) {
    const query = this.createHttpParams({
      pageNo: params?.pageNo,
      pageSize: params?.pageSize,
      search: params?.search,
      position: params?.position,
      sortBy: params?.sortBy,
    });
    return this.http.get<any>(
      this.url + ApiEndpoints.UserMarginAndDiscount.base,
      { params: query }
    );
  }

  createUserMarginAndDiscount(payload: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.UserMarginAndDiscount.base,
      payload
    );
  }

  updateUserMarginAndDiscount(id: string, payload: any) {
    return this.http.put<any>(
      this.url + ApiEndpoints.UserMarginAndDiscount.base + '/' + id,
      payload
    );
  }

  // User billing
  createBillingSession() {
    return this.http.get<any>(this.url + ApiEndpoints.UserPortal.session);
  }

  // advance payment
  payForPlanInAdvance(payload: any) {
    return this.http.post<any>(this.url + ApiEndpoints.MailboxProfiels.PayForPlanInAdvance, payload);
  }

  // advance payment
  verifyAdvancePlanPayment(payload: any) {
    return this.http.post<any>(this.url + ApiEndpoints.MailboxProfiels.VerifyAdvancePlanPayment, payload);
  }

  // get plans public
  getUserPlans() {
    return this.http.get<any>(this.url + ApiEndpoints.Plans.plans);
  }

  subscription(payload: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Users.Subscription,
      payload
    );
  }

  contact(payload: any) {
    return this.http.post<any>(
      this.url + ApiEndpoints.Users.Contact,
      payload
    );
  }
}
