import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  private updateTabsSubject = new BehaviorSubject<boolean>(false);
  updateTabs$ = this.updateTabsSubject.asObservable();

  triggerTabUpdate() {
    this.updateTabsSubject.next(true);
  }
}
