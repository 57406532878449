import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { StorageKeys } from '../config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  isAuthenticated(): boolean {
    const token = this.getAuthToken();
    if (token) return true;
    return false;
  }

  getAuthToken(): any {
    return localStorage.getItem(StorageKeys.AuthTokenKey);
  }

  storeAuthToken(token: string) {
    localStorage.setItem(StorageKeys.AuthTokenKey, token);
  }

  storeAdminToken(token: string) {
    localStorage.setItem('adminToken', token || '');
  }

  getAdminToken() {
    return localStorage?.getItem('adminToken') || '';
  }

  storeSubAdminToken(token: string) {
    localStorage.setItem('subAdminToken', token);
  }

  removeToken() {
    localStorage.removeItem(StorageKeys.AuthTokenKey);
  }

  storeRole(role: string) {
    localStorage.setItem(StorageKeys.UserRoleKey, role);
  }

  storeUserId(id: string) {
    localStorage.setItem(StorageKeys.UserId, id);
  }

  getUserId(): string {
    return localStorage?.getItem(StorageKeys.UserId) || '';
  }

  removeUserId(): void {
    localStorage?.removeItem(StorageKeys.UserId);
  }

  storePlanId(id: string) {
    localStorage.setItem(StorageKeys.PlanId, id);
  }

  removePlanId() {
    localStorage.removeItem(StorageKeys.PlanId);
  }

  getPlainId(): string {
    return localStorage?.getItem(StorageKeys.PlanId) || '';
  }

  storeUserProfile(data: any) {
    localStorage.setItem(StorageKeys.CurrentProfile, JSON.stringify(data));
  }

  storeAdminProfile(data: any) {
    localStorage.setItem(StorageKeys.AdminProfile, JSON.stringify(data));
  }

  storeSubAdminProfile(data: any) {
    localStorage.setItem(
      StorageKeys.StoreSubAdminProfile,
      JSON.stringify(data)
    );
  }

  accessAdminProfile(data: any) {
    localStorage.setItem(StorageKeys.AccessAdminProfile, JSON.stringify(data));
  }

  storeApplicationFrom(data: any) {
    localStorage.setItem(StorageKeys.ApplicationFrom, JSON.stringify(data));
  }

  storeApplicationFrom2(data: any) {
    localStorage.setItem(StorageKeys.ApplicationFrom2, JSON.stringify(data));
  }

  getApplicationFrom(): any {
    if (localStorage?.getItem(StorageKeys.ApplicationFrom)) {
      return JSON.parse(
        localStorage?.getItem(StorageKeys.ApplicationFrom) || ''
      );
    } else {
      return '';
    }
  }

  getApplicationFrom2(): any {
    if (localStorage?.getItem(StorageKeys.ApplicationFrom2)) {
      return JSON.parse(
        localStorage?.getItem(StorageKeys.ApplicationFrom2) || ''
      );
    } else {
      return '';
    }
  }

  removeApplicationFrom() {
    localStorage.removeItem(StorageKeys.ApplicationFrom);
  }

  storeShipmentServices(data: any) {
    localStorage.setItem('shipmentServices', JSON.stringify(data));
  }

  getSubAdminProfile() {
    if (localStorage.getItem(StorageKeys.StoreSubAdminProfile)) {
      return JSON.parse(
        localStorage?.getItem(StorageKeys.StoreSubAdminProfile) || ''
      );
    } else {
      return '';
    }
  }

  getShipmentServices(): any {
    if (localStorage?.getItem('shipmentServices')) {
      return JSON.parse(localStorage?.getItem('shipmentServices') || '');
    } else {
      return '';
    }
  }

  // getUserProfile(): any {
  //   if (localStorage?.getItem(StorageKeys.CurrentProfile)) {
  //     return JSON.parse(
  //       localStorage?.getItem(StorageKeys.CurrentProfile) || ''
  //     );
  //   } else {
  //     return '';
  //   }
  // }

  getUserProfile(): any {
    const localProfile = localStorage?.getItem(StorageKeys.CurrentProfile);
    if (localProfile) {
      return JSON.parse(localProfile!);
    } else {
      return '';
    }
  }

  isFirstTimeUser(): boolean {
    const user = this.getUserProfile()
    return !user?.isPlanPurchasedFirstTime
  }

  isUserPlanExpired(): boolean {
    const user = this.getUserProfile()
    return (
      !user?.isPlanPurchased ||
      !user?.isPlanExist ||
      user?.userPlan?.status !== 'active' ||
      user?.userPlan?.isPlanExpired ||
      new Date(user?.userPlan?.endDate) < new Date()
    );
  }

  getAdminProfile(): any {
    if (localStorage?.getItem(StorageKeys.AdminProfile)) {
      return JSON.parse(localStorage?.getItem(StorageKeys.AdminProfile) || '');
    } else {
      return '';
    }
  }

  getAccessAdminProfile(): any {
    if (localStorage?.getItem(StorageKeys.AccessAdminProfile)) {
      return JSON.parse(
        localStorage?.getItem(StorageKeys.AccessAdminProfile) || ''
      );
    } else {
      return '';
    }
  }

  getUserAccountType(): string {
    return localStorage?.getItem(StorageKeys.UserAccountType) || '';
  }

  getAdditionalInfo(): any {
    if (localStorage?.getItem('additionalinfoForm')) {
      return JSON.parse(localStorage?.getItem('additionalinfoForm') || '');
    } else {
      return '';
    }
  }

  removeUserProfile() {
    localStorage.removeItem(StorageKeys.CurrentProfile);
  }

  logout() {
    this.clearStorage();
    this.ngZone.run(() => this.router.navigate(['/']));
  }

  storeTokens(token: string) {
    this.localStorageService.set(StorageKeys.AuthTokenKey, token);
  }

  storeIsAReferredSubAdminAccessed(value?: boolean) {
    this.localStorageService.set('isAReferredSubAdminAccessed', String(value));
  }

  getIsAReferredSubAdminAccessed() {
    return this.localStorageService.get('isAReferredSubAdminAccessed');
  }

  clearStorage() {
    this.localStorageService.remove(StorageKeys.AuthTokenKey);
    this.localStorageService.remove(StorageKeys.UserRoleKey);
    this.localStorageService.remove(StorageKeys.ApplicationModeKey);
  }
}
