import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToasterService } from './../services/toaster.service';
import { Router } from '@angular/router';
import { AuthService, LocalStorageService } from '../services';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  user: any;
  constructor(
    private toasterService: ToasterService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private zone: NgZone,
    private _router: Router
  ) {
    const user = this.authService.getUserProfile();
    this.user = user;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => this.handleResponse(event)),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  private handleResponse(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      const message = event.body || '';
      if (message?.isSuccess) {
        // if (message?.message) {
        //   this.toasterService.successToast(message.message);
        // }
      } else {
        if (message?.message) {
          this.toasterService.errorToast(message?.message);
        }
      }
    }

    return event;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';

    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      if (error.status === 401 || error.status === 403) {
        this.toasterService.errorToast(
          'Your session has expired due to inactivity. Please log in again.'
        );
        let adminToken: any = localStorage.getItem('adminToken');
        let adminProfile = this.authService.getAccessAdminProfile();

        if (adminToken && adminProfile) {
          this.switchToAdminSession(adminToken, adminProfile);
        } else {
          this.localStorageService.clear();
          this._router.navigate(['/home']);
        }
      } else if (error.status === 402) {
        let adminToken: any = localStorage.getItem('adminToken');
        let adminProfile = this.authService.getAccessAdminProfile();

        if (adminToken && adminProfile) {
          this.switchToAdminSession(adminToken, adminProfile);
        } else {
          this.localStorageService.clear();
          this._router.navigate(['/home']);
        }
        this.toasterService.errorToast(
          'Your plan has expired. Please renew to continue.'
        );
      } else if (error.error?.message) {
        errorMessage = error.error.message;
      } else {
        errorMessage = `Error Message: ${error.message}`;
      }
    }

    return throwError(() => errorMessage);
  }

  private switchToAdminSession(adminToken: string, adminProfile: any): void {
    this.localStorageService.clear();
    this.authService.storeUserProfile(adminProfile);
    this.authService.storeUserId(adminProfile?.id);
    this.authService.storeAuthToken(adminToken);
    this._router.navigate(['/admin/customerlist']);
  }
}
