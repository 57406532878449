export const ApiEndpoints = {
  Auth: {
    Login: 'auths/signin',
    UpdatePassword: 'auths/updatePassword',
    ForgotPassword: 'auths/forgotPassword',
    ResetPassword: 'auths/resetPassword',
    SignUp: 'auths/signup',
    Logout: 'auths/logout',
  },

  Common: {
    CountryList: 'addresses/country/list',
    StateList: 'addresses/state/list',
    CityList: 'addresses/city/list',
  },

  Addresses: {
    AddAddress: 'addresses',
    GetAddressDetail: 'addresses',
    UpdateAddress: 'addresses',
    DeleteAddress: 'addresses',
    GetAddress: 'addresses',
    ExportAddress: 'addresses/export/data',
    ImportAddress: 'addresses/import/data',
  },

  Packages: {
    AddPackage: 'packages/createSinglePackage',
    GetPackagesDetail: 'packages',
    UpdatePackage: 'packages',
    DeletePackage: 'packages',
    GetPackageDetail: 'packages',
  },

  Shipments: {
    AddShipment: {
      AddAddress: 'addresses',
    },
    DeleteShipment: 'shipments',
    ApproveOrReject: 'profiles/shipmentaccount/approved',
    GetShipments: 'shipments',
    GetBulkShipments: 'excelShipments',
    DeleteBulkShipments: 'excelShipments',
    GetShipmentsPaidStatus: 'shipmentPaymentHistories/check/unpaid/invoice',
    CreateSenderAddress: 'shipments/createSenderAddress',
    UpdateSenderAddress: 'shipments/updateSenderAddress',
    CreateReceiverAddress: 'shipments/createReceiverAddress',
    VerifyAdress: 'addresses/validateAddress',
    SwapAddress: 'shipments/swap/address',
    SavePackages: 'shipments/savePackages',
    AddFedExPackage: 'shipments/createPackages',
    AddFedExOneRatePackage: 'shipments/createPackagesOneRate',
    AddPackageForUps: 'shipments/createPackagesForUps',
    GetAllServices: 'shipments/createCombineShipmentPackage',
    GetUPSServices: 'shipments/createCombineShipmentPackage',
    SelectService: 'shipments/selectService',
    UpdateQuotes: 'shipments/updateQuote',
    UpdateQuotesForUps: 'shipments/updateQuoteUPS',
    CreateShipment: 'shipments/createShipment',
    CreateUpsShipment: 'shipments/createUPSShipment',
    CreateReturnShipment: 'shipments/createReturnShipment',
    CreateReturnUpsShipment: 'shipments/createReturnShipmentUps',
    CancelShipment: 'shipments/cancelShipment',
    DeleteCancelShipment: 'shipments/deleteShipmentApi',
    PaymentShipment: 'shipments/shipmentPayment',
    CopyShipment: 'shipments/copyShipment',
    AddCard: 'userCards',
    GetCard: 'userCards',
    PrintLabel: 'shipments/trackingNumberAndLable',
    EmailLabel: 'shipments/emailLabel',
    PrintMultipleLabel: 'shipments/mergePdfFunction',
    Payment: 'shipments/isPaymentSuccess',
    BulkShipment: '/shipments/import',
    TrackMyShipment: 'shipments/track/shipment',
  },

  Pickup: {
    List: 'pickups',
    Create: 'pickups/create',
    Cancel: 'pickups/cancel',
    UpdateStatuses: 'pickups/update/pickups/all'
  },

  PickupAddresses: {
    Create: 'pickupAddresses',
    Remove: 'pickupAddresses/removeAddress',
    List: 'pickupAddresses',
  },

  Dashboard: {
    ShipmentDashboardSaving: 'dashboards/shipmentSavingGraph',
    ShipmentDashboarShipmentCount: 'dashboards/shipmentCount',
    MailboxGraph: 'dashboards/mailboxGraph',
    MailboxCount: 'dashboards/MailBoxStatusCountApi',
    Status: 'dashboards/dashboardStatusApi',
    AdminAccountGraph: 'dashboards/adminAccountsGraph',
    SubAdminAccountGraph: 'dashboards/revenue/subAdmin',
    SubAdminCommissionList: 'dashboards/subAdmin/totalPaymentList',
    SubAdminCommissionUserList: 'dashboards/subAdmin/totalComissionList',
  },

  MailboxProfiels: {
    Profiles: 'profiles',
    MailBoxes: 'mailBoxes',
    MailBoxesPost: 'mailBoxStores/assignMailBoxIdAndNumber',
    mailBoxStore: 'mailBoxStores/mailBox/Stores',
    mailBoxNumberList: 'mailBoxStores/getEmptyMailBoxesList',
    ApproveReject: 'profiles/approveOrReject',
    Plans: 'plans',
    MarkActiveInactive: 'mailBoxes',
    BuyPlans: 'userPlans/buyPlan',
    ChangePlan: 'userPlans/change/plan',
    VerifyChangePlan: 'userPlans/verify/change/plan',
    GetAfterPurchaseScanCount: 'userPlans/purchase/count',
    Payment: 'userPlans/isPaymentSuccess',
    InboxList: 'assignLabels',
    OpenAndScanReq: 'assignLabels/openAndScanReq',
    OpenAndScanRes: 'assignLabels/openAndScanRes',
    ForwardLabel: 'assignLabels/forwardLabel',
    ForwardMultipleLabel: 'assignLabels/forwardMultipleLabels',
    HoldForPickupOtp: 'assignLabels/holdForPickupOtp',
    HoldForPickupMultipleOtp: 'assignLabels/holdForPickupOtp/MultipleLabels',
    HoldForPickup: 'assignLabels/holdForPickup',
    HoldForPickupVerify: 'assignLabels/holdForPickup',
    NotMyMail: 'assignLabels/notMyMail',
    AddInTrash: 'assignLabels/addInTrash',
    AddInShred: 'assignLabels/addInShred?profileId=',
    AssignLabels: 'assignLabels',
    CancelLabels: 'assignLabels/cancelLabel',
    CancelMultipleLabels: 'assignLabels/cancel/multiple',
    MarkAsPickup: 'assignLabels',
    MarkAsPickupMultiple: 'assignLabels/markAsPickup',
    AssignPlanToUser: 'userPlans/addPlansToUser',
    AssignPlanToSubAdmin: 'userPlans/addPlansToSubAdmin',
    AssignScanCount: 'userPlans/addScans',
    ForwardResponse: 'assignLabels/forward/response',
    SelectForwardPlan: 'assignLabels/forwardLabelSelectPlan',
    SelectForwardPlanPayment: 'assignLabels/forwardLabelPayment',
    CheckForwardPayment: 'assignLabels/isPaymentSuccess',
    AddShipmentDate: 'assignLabels/forwardLabelAdminResponse',
    GetMailboxScansCount: 'assignLabels/totalCount',
    ChangeDueDate: 'userPlans',
    SendToArchive: 'assignLabels',
    PermanentDeleteMailbox: 'assignLabels/permanantDelete',
    RemoveUserFromMailBox: 'mailBoxes/removeMailBoxUser',
    MailBoxUniqueNumber: 'mailBoxes/getMailBoxByAssignId',
    CancleSubscriptionPlan: 'userPlans/cancleSubscriptionPlan',
    CancleSubscription: 'userPlans/cancel/subscription',
    AcceptDiscount: 'userPlans/accept/discount',
    PayForPlanInAdvance: 'userPlans/payForPlanInAdvance',
    VerifyAdvancePlanPayment: 'userPlans/verifyAdvancePlanPayment',
  },

  Users: {
    LoginToSubAdminAcc: 'users/get/SubAdminToken',
    GetUserDetail: 'users',
    ChangeUserPosition: 'marginAndDiscounts/addUserToVip',
    GetUserScanCount: 'users/userScanCount/List',
    GetShipMentUserDetail: 'users/user/list',
    InviteUser: 'users/invite/member',
    CreateProfile: 'users/profile',
    UpdateProfile: 'profiles',
    UploadDocs: 'profiles',
    AddUser: 'users/addCustomer',
    UserTag: 'users/returnPosition/TagOnly',
    UpgradeAccount: 'profiles/upgradeAccount',
    DeleteUserForAdmin: 'users/deleteUserAPIForAdmin',
    updateTour: 'users/updateTour',
    verifyConfigPass: 'users/verifyConfigPass',
    ChangeEmail: 'users/changeEmail',
    Subscription: 'users/subscription',
    Contact: 'users/contact',
  },

  SubAdmin: {
    AddSubAdmin: 'users/add/sub/admin',
    DeleteSubAdmin: 'users/delete/sub/admin',
    GetSubAdminAccountSetting: '',
    logoChangeSubAdmin: 'users',
    MakeSuperSubadmin: 'users/createSuperSubAdmin',
  },

  Invoices: {
    SubAdminMandatoryInvoiceList: 'paymentHistories',
    SubAdminInvoices: 'paymentHistories',
    ShipmentInvoices: 'shipmentPaymentHistories',
    MonthlyInvoices: 'userMonthlyBillings',
    GetUnpaidInvoices: 'userMonthlyBillings/unpaid/invoices',
    RaiseMonthlyBilling: 'userMonthlyBillings/raiseMonthlyInvoice',
    PayMonthlyBilling: 'userMonthlyBillings/payMonthlyInvoice',
    PayPendingInvoice: 'userMonthlyBillings/payPendingInvoice',
    DownloadMonthlyInvoice: 'userMonthlyBillings/generatePDF',
    VerifyMonthlyBillPayment: 'userMonthlyBillings/verifyMonthlyInvoicePayment',
    Penalties: 'userPenalties',
    ShipmentInvoiceItems: 'shipmentInvoiceItems',
    CreateManualPenalties: 'userPenalties/createManualPenalty',
    CancelPenalty: 'userPenalties/cancelPenalty',
    CancelMultipleUserPenalties: 'userPenalties/cancelMultipleUserPenalties',
    ShipmentInvoicesCount: 'shipmentPaymentHistories/check/unpaid/invoice',
    // MailboxInvoicesCount: 'PaymentHistories/getUnpaid/Invoice/Count',
    MailboxInvoicesCount: 'userMonthlyBillings/unpaid/count',
    // getSubMailboxUnpaidCount:
    //   'PaymentHistories/getUnpaid/InvoiceCount/ForSubAdminUsers',
    getSubMailboxUnpaidCount:
      'userMonthlyBilling/getUnpaid/InvoiceCount/ForSubAdminUsers',
    getSubAdminCount: 'PaymentHistories/getUnpaid/InvoiceCount/ForSubAdmin',
    MailboxInvoices: 'paymentHistories',
    downloadShipmentInvoice: 'shipmentPaymentHistories/generatePDF',
    downloadMailboxInvoice: 'paymentHistories/generatePDF',
    PayInvoice: 'assignLabels/forwardLabelPayment',
    PayAdminMailboxInvoice: 'paymentHistories/userInvoicePaymentAPI',
    PayAutomaticPlanInvoice: 'PaymentHistories/userInvoicePaymentAPI',
    AttachExistingShipmentInvoice: 'shipmentPaymentHistories/attachInvoiceItem',
    payShipmentInvoice: 'shipmentPaymentHistories/userInvoicePaymentAPI',
    paySubAdminInvoice: 'paymentHistories/userInvoicePaymentAPI',
    // PaymentVerification:'assignLabels/isPaymentSuccess',
    PaymentVerificationPlan: 'PaymentHistories/isPaymentSuccess',
    ShipmentPaymentVerification: 'shipmentPaymentHistories/isPaymentSuccess',
    // CreateMailboxInvoice: 'paymentHistories/assignInvoiceToUser',
    CreateMailboxInvoice: 'userMonthlyBillings/assignInvoiceToUser',
    CreateShipmentInvoice: 'shipmentPaymentHistories/assignInvoiceToUser',
    CancelSubAdminInvoice: 'paymentHistories/delete/invoice',
    CancelInvoice: 'shipmentPaymentHistories/delete/invoice',
    CancelSpecialInvoice: 'shipmentPaymentHistories/delete/SpecialInvoice',
    // CancelMailboxInvoice: 'paymentHistories/delete/invoice',
    CancelMailboxInvoice: 'userMonthlyBillings/cancelInvoice',
    Calculate: 'shipmentPaymentHistories/calculate/InvoicesAmount',
    CreateSpecialInvoice: 'shipmentPaymentHistories/create/SpecialInvoice',
  },

  Carrier: {
    Carriers: 'carriers',
  },

  MarginAndDiscount: {
    MarginAndDiscount: 'marginAndDiscounts',
    VipMarginAndDiscount: 'marginAndDiscounts/updatemargin',
  },

  Faq: {
    GetFaq: 'faqs',
    AddFaq: 'faqs',
    EditFaq: 'faqs',
    DeleteFaq: 'faqs',
  },

  AnalyticsAndReports: {
    Logs: 'logs',
    Locations: 'users/get/long/lat',
    Revenue: 'dashboards/totalRevenueAPI',
  },

  Notifications: {
    GetNotifications: 'notifications',
  },

  Store: {
    GetStoreList: 'mailBoxStores',
    GetStoreDetail: 'mailBoxStores',
    AddStore: 'mailBoxStores',
    CheckStoreName: 'mailBoxStores/checkMailBoxStore/NameExistOrNot',
    GetStoreMailBoxDetail: '',
    ActiveInactiveStore: '',
    DeleteStore: 'mailBoxStores',
    UpdateStoreStatus: 'mailBoxStores',
    GetStoreMailBoxes: 'mailBoxes',
    getEmptyStoreList: 'mailBoxStores/findEmptyMailBoxStore',
    // inActiveStore: 'mailBoxStores/inActiveStore',
    switchMailBoxStore: 'mailBoxStores/switchMailBoxStore',
    deleteInactiveStore: 'mailBoxStores/deletedStore',
    addMoreMailboxes: 'mailBoxStores/addMoreMailBoxes',
  },

  DownloadForm: {
    DownloadMailboxForm: 'profiles/downloadPdfForm',
  },

  DeleteUserProfile: {
    DeleteUserProfile: 'profiles/deleteProfile',
  },

  setConfigPassword: {
    setPassword: 'users/setConfigPass',
    updateConfigPass: 'users/updateConfigPass',
  },

  stripeConnent: {
    connenctWithStripe: 'users/connect/account',
  },

  checkAccountStatus: {
    accountStatus: 'users/connect/accountStatus',
  },

  UserMarginAndDiscount: {
    base: 'userMarginAndDiscounts',
    getUsers: 'users/margin/discount',
  },

  UserPortal: {
    session: 'users/billing/portal',
  },

  Financial: {
    session: 'users/financial/session',
  },

  Plans: {
    plans: 'plans/public/search?serverPaging=false',
  },

  Upload: {
    Images: 'images/upload'
  }
};
