import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DebounceService {
  private subject = new Subject<string>();

  constructor() { }

  debounce(callback: (value: string) => void, time: number = 800) {
    this.subject.pipe(debounceTime(time)).subscribe(callback);
  }

  next(value: string) {
    this.subject.next(value);
  }
}
